

/* ********************** */
  /* TAILWIND */
  @tailwind components;
  @tailwind utilities;
  /* END TAILWIND */
/* ********************** */

/* ********************** */
  /* GENERAL */
  body {
    margin: 0px;
  }
  /* END GENERAL */
/* ********************** */
